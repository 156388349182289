import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import CheckoutSummary from './subcomponents/CheckoutSummary';
import CustomerDetails from './subcomponents/CustomerDetails';
import FamilyDiscountDetails from './subcomponents/FamilyDiscountDetails';
import NewOrder from '../cart/NewOrder';
import KeepShoppingButton from '../cart/KeepShoppingButton';
import DiscountCode from './subcomponents/DiscountCode';
import {
  resetSubmittingOrder,
  storeCart,
  submitCart,
} from '../../../actions/v3Order';

// Adds padding to account for missing banner message component
const CheckoutWrapper = styled.div`
  padding-top: 24px;
`;

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const CheckoutRight = styled.div`
  float: right;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Checkout = () => {
  const dispatch = useDispatch();
  const { cartTotals, discountCodes, storingCart, submittingOrder } =
    useSelector((state) => state.v3Order);
  const stripeCancelUrl = window.location.href;

  useEffect(() => {
    dispatch(resetSubmittingOrder());
    dispatch(storeCart());
  }, []);

  const handleSubmit = () => {
    dispatch(submitCart(stripeCancelUrl));
  };

  const getLoadingSpinnerClass = () => {
    if (storingCart || submittingOrder) {
      return 'loading';
    }

    return 'hidden';
  };

  return (
    <CheckoutWrapper>
      <div className={getLoadingSpinnerClass()}>
        <div className="spinner" />
      </div>
      <MainHeader>Customer Details &amp; Payment</MainHeader>
      <Divider />
      <div className="order-checkout__main-content row">
        <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
          <CustomerDetails cartTotals={cartTotals} />
          <FamilyDiscountDetails cartTotals={cartTotals} />
        </div>
        <CheckoutRight className="col-xs-12 col-md-4 col-lg-4">
          <CheckoutSummary
            cartTotals={cartTotals}
            discountCodes={discountCodes}
            handleSubmit={handleSubmit}
            submittingOrder={submittingOrder}
          />
          <DiscountCode />
          <NewOrder />
          <KeepShoppingButton />
        </CheckoutRight>
      </div>
    </CheckoutWrapper>
  );
};

export default Checkout;
