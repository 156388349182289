import React from 'react';
import styled from 'styled-components';
import CheckoutSummaryLineItem from './CheckoutSummaryLineItem';
import CheckoutPaymentButton from './CheckoutPaymentButton';
import cartUtils from '../../../../utils/cart';

const SummaryBox = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 0;
  text-align: center;
`;

const SummaryHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const CheckoutSummary = ({
  cartTotals,
  discountCodes,
  handleSubmit,
  submittingOrder,
}) => {
  const containsFreeShippingDiscount =
    discountCodes?.some((discountCode) => {
      return discountCode.is_free_shipping;
    }) || false;

  return (
    <SummaryBox>
      <SummaryHeader>Order Summary</SummaryHeader>
      <CheckoutSummaryLineItem label="Subtotal" price={cartTotals['total']} />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Discount"
        price={cartTotals['discount']}
      />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Family Discount"
        price={cartTotals['familyDiscount']}
      />
      {/* Only show shipping if greater than 0 */}
      {cartUtils.cartHasShippingCost(cartTotals) && (
        <CheckoutSummaryLineItem
          label="Shipping"
          showFreeIfDiscount={containsFreeShippingDiscount}
          price={cartTotals['shipping']}
        />
      )}
      <CheckoutSummaryLineItem label="Tax" price={cartTotals['tax']} />
      <CheckoutSummaryLineItem
        label="Grand Total"
        price={cartTotals['grandTotal']}
        showZeroIfZero={true}
      />
      <CheckoutPaymentButton
        grandTotal={cartTotals['grandTotal']}
        handleSubmit={handleSubmit}
        submittingOrder={submittingOrder}
      />
    </SummaryBox>
  );
};

export default CheckoutSummary;
