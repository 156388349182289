/* eslint-disable react/prop-types */
import React from 'react';
import close from '../../../../assets/images/close.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  clearProductConfig,
  setProductConfig,
  removeIndexFromProductConfig,
  addToCart,
  removeItemFromConfig,
} from '../../../actions/v3Order';
import formatUSD from '../../../utils/formatUSD';
import { getProductByLocation } from '../helpers/store';
import useScreenSize from '../hooks/screen';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import pluralize from 'pluralize';

const SlotSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  width: min-content;
  align-items: center;
  min-width: 260px;
  h3 {
    line-height: 30px;
  }
`;

const SlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  height: 125px;
  width: 125px;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  position: relative;
  border: ${(props) => (props.selected ? '2px solid #479DE3' : 'initial')};
  color: ${(props) => (props.selected ? '#479DE3' : 'initial')};
`;

const Slots = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-auto-rows: min-content;
  gap: 10px;
  margin-top: 15px;
`;

const SlotProductImage = styled.img`
  height: 85px;
`;

const SlotImageWrapper = styled.div`
  height: 85px;
  width: 65px;
  position: relative;
`;

const CloseIcon = styled.img`
  height: 13px;
  width: 13px;
  position: absolute;
  right: 8px;
  top: 10px;
  cursor: pointer;
`;

const BackgroundProductImage = styled.img`
  height: 85px;
`;

const PoseImage = styled.img`
  position: absolute;
  bottom: 0px;
  height: 85px;
  left: 0px;
`;

const ProductTitle = styled.div`
  font-size: 12px;
  margin-top: 2px;
  text-align: center;
  color: #000000;
`;

const SlotTitle = styled.div`
  text-align: center;
  color: ${(props) => (props.free ? '#479DE3' : 'initial')};
`;

const Slot = ({
  number,
  product,
  currentSlot,
  background,
  pose,
  slotCount,
  location,
  history,
  freeIndices,
}) => {
  const dispatch = useDispatch();

  const onClickSlotWrapper = () => {
    if (location.hash !== '#slots') return;
    if (currentSlot === slotCount)
      return Swal.fire(`Please click on Done, Add to Cart.`);
    if (number !== currentSlot)
      return Swal.fire(
        `Please tap item ${currentSlot + 1} to begin configuring.`,
      );
    history.push(location.pathname);
  };

  const remove = (event) => {
    event.stopPropagation();
    dispatch(removeItemFromConfig(number, history));
  };

  return (
    <SlotWrapper selected={currentSlot === number} onClick={onClickSlotWrapper}>
      {product && <CloseIcon onClick={remove} src={close} />}
      {!product && !freeIndices?.includes(number) && (
        <SlotTitle>Item {number + 1}</SlotTitle>
      )}
      {!product && freeIndices?.includes(number) && (
        <SlotTitle free>
          FREE
          <br />
          Item {number + 1}
        </SlotTitle>
      )}
      {product && !background && !pose && (
        <SlotProductImage src={product.image_thumbnail} />
      )}
      {(background || pose) && (
        <SlotImageWrapper>
          {background && (
            <BackgroundProductImage src={background.image_thumbnail} />
          )}
          {pose && (
            <PoseImage
              src={pose.url}
              onContextMenu={(event) => event.preventDefault()}
            />
          )}
        </SlotImageWrapper>
      )}
      {product && <ProductTitle>{product.name}</ProductTitle>}
    </SlotWrapper>
  );
};

const ActionButton = styled.button`
  border: none;
  align-self: stretch;
  background: #479DE3;
  height: 42px;
  padding: 10px;
  font-size 14px;
  color: white;
  border-radius: 3px;
  font-weight: 800;
  margin-top: 30px;

  :disabled {
    background: gray;
    pointer-events: none;
    cursor: initial;
    opacity: 0.3;
  }
`;

const Instruction = styled.span`
  color: #479de3;
`;

const SlotSelector = ({
  actionButtonTitle = 'Done, Add to Cart',
  shoot,
  shootProducts,
  productConfig,
  location,
  history,
  additionalData,
  pages,
  instruction = 'Select a Product',
  ...props
}) => {
  const background_choice_mode = useSelector(
    (state) => state.shoot?.shoot?.background_choice_mode,
  );

  const isGreeenScreenEvent =
    background_choice_mode === 'advanced_greenscreen_backgrounds_only';

  const products = Object.values(productConfig).reduce(
    (a, b) => (b.product ? [...a, b.product] : a),
    [],
  );
  const backgrounds = Object.values(productConfig).reduce(
    (a, b) => (b.background ? [...a, b.background] : a),
    [],
  );
  const poses = Object.values(productConfig).reduce(
    (a, b) => (b.pose ? [...a, b.pose] : a),
    [],
  );
  const dispatch = useDispatch();

  const product = getProductByLocation(shoot?.shootProducts, location);

  const {
    name,
    price,
    maximum_children_count: slotCount,
    additional_data = {},
    additional_data: { free_indices = [] } = {},
    required_children_count,
    ...otherProperties
  } = product;

  const isYouPickX = Object.keys(additional_data).length == 0;
  const isBYO = Object.keys(additional_data).length > 0;

  console.log(`product type -- ${isYouPickX ? 'You Pick X' : 'BYO'}`);

  const minimumChildCount =
    isYouPickX && Number.isInteger(required_children_count)
      ? required_children_count
      : isYouPickX
      ? slotCount
      : 1;

  const actionButtonEnabled =
    (poses.length === 0 || poses.length === products.length) &&
    (backgrounds.length === 0 || backgrounds.length === products.length) &&
    [...products, ...poses, ...backgrounds].length >=
      pages.length * minimumChildCount;

  const [screenSize] = useScreenSize();

  const submitOrder = () => {
    // TODO: handle poses

    if (isBYO) {
      if ([...products].length < minimumChildCount)
        return Swal.fire(
          `Please configure at least ${minimumChildCount} item(s).`,
        );

      if ([...products].length > [...backgrounds].length && isGreeenScreenEvent)
        return Swal.fire(`Please finish configuring all items.`);
    }

    if (isYouPickX && !actionButtonEnabled) {
      return Swal.fire(
        `Please configure ${minimumChildCount} ${pluralize(
          'item',
          minimumChildCount,
        )}.`,
      );
    }

    const fullProduct = getProductByLocation(shoot.shootProducts, location);

    dispatch(
      addToCart({
        studentId: additionalData.studentId,
        fullproductData: fullProduct,
      }),
    );
  };

  let priceToDisplay = price;

  if (isBYO) {
    let freeIndices = [];

    if (additional_data?.free_indices) {
      freeIndices = additional_data.free_indices;
    }

    console.log('freeIndices', freeIndices);

    priceToDisplay = products.reduce((sum, product, index) => {
      if (!freeIndices.includes(index)) {
        return sum + product.price;
      }
      return sum;
    }, 0);
  }

  return (
    <SlotSelectorWrapper>
      {(screenSize === 'Desktop' || location.hash === '#slots') && (
        <h3 style={{ wordBreak: 'break-word' }}>
          {name} <br />
          {formatUSD(priceToDisplay)} - <Instruction>{instruction}</Instruction>
        </h3>
      )}
      <Slots>
        {Array.from(Array(slotCount)).map((_, i) => (
          <Slot
            freeIndices={free_indices}
            slotCount={slotCount}
            location={location}
            currentSlot={parseInt(
              location.pathname.split('/').pop().replace('#slots', ''),
            )}
            pose={poses[i]}
            background={backgrounds[i]}
            product={products[i]}
            shootProducts={shootProducts}
            shoot={shoot}
            history={history}
            additionalData={additionalData}
            number={i}
            {...props}
          />
        ))}
      </Slots>
      <ActionButton onClick={submitOrder}>{actionButtonTitle}</ActionButton>
    </SlotSelectorWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    additionalData: state.additionalData,
    shoot: state.shoot,
    order: state.v3Order,
    shootProducts: state.shoot.shootProducts,
    productConfig: state.v3Order.productConfig,
    hasPoses: state.additionalData?.availablePoses?.length,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setProductConfig,
      clearProductConfig,
      removeItem: removeIndexFromProductConfig,
      addToCart: addToCart,
    },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlotSelector),
);
