import React from 'react';
import cartUtils from '../../../utils/cart';

const CartItemComponentsList = ({ cartItem }) => {
  if (!cartUtils.cartItemProductHasContentsArray(cartItem)) {
    return null;
  }

  return (
    <ul>
      {cartItem.contents.map((content, i) => (
        <li key={`child-item-${cartItem.id}-${i}`}>{content?.trim()}</li>
      ))}
    </ul>
  );
};

export default CartItemComponentsList;
