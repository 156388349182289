import React from 'react';
import { withRouter } from 'react-router-dom';

const BackLink = ({ history }) => (
  <div className="bnl-product-group__back-button" onClick={history.goBack}>
    &larr; Back
  </div>
);

export default withRouter(BackLink);
