import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { signupParentStep1 } from '../../actions/action_auth';
import history from '../../utils/history';

const StyledLink = styled.a`
  text-decoration: underline;
  color: #337ab7;
  &:hover {
    background-color: #337ab7;
    color: #ffffff;
  }
`;

const CreateAccountError = styled.div`
  a {
    text-decoration: underline;
    color: #a94442;
    background: none;
    &:hover {
      background-color: #a94442;
      color: #f2dede;
      text-decoration: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

class NewParentSignupFormStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errors: {},
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderEmailErrors = this.renderEmailErrors.bind(this);
    this.renderPasswordErrors = this.renderPasswordErrors.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    this.setState({ errors: {} });

    const { email, password } = this.state;

    if (email.length === 0) {
      this.setState({ errors: { email: 'Error: email cannot be blank.' } });
      return;
    }

    if (password.length < 8) {
      this.setState({
        errors: {
          password: 'Error: password must be at least 8 characters in length.',
        },
      });
      return;
    }

    const that = this;
    const data = {
      email,
      password,
    };

    this.props.signupParentStep1(data).then((response) => {
      if (response.payload.data) {
        if (!response.payload.data.errors) {
          that.props.updateStep(2);
        } else if (
          response.payload.data.errors &&
          response.payload.data.errors.email &&
          response.payload.data.errors.email.includes(
            'a user with this email already exists',
          )
        ) {
          history.push({
            pathname: '/password-lookup',
            search: `?email=${encodeURIComponent(email)}`,
            state: { email },
          });
          window.location.reload(true);
        } else {
          that.setState({ errors: response.payload.data.errors });
        }
      }
    });
  }

  handleShowPasswordClick() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  renderEmailErrors() {
    const { errors } = this.state;

    if (errors.email) {
      return (
        <CreateAccountError className="alert alert-danger">
          <p>{errors.email}</p>
        </CreateAccountError>
      );
    }

    return null;
  }

  renderPasswordErrors() {
    const { errors } = this.state;

    if (errors.password) {
      return (
        <CreateAccountError className="alert alert-danger">
          <p>{errors.password}</p>
        </CreateAccountError>
      );
    }

    return null;
  }

  render() {
    const { email, password, showPassword } = this.state;

    return (
      <Wrapper className="row row-no-gutters">
        <div className="col-lg-6 col-md-8 col-xs-12">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3 className="panel-title text-center">Create a New Account</h3>
            </div>
            <div className="panel-body">
              <p>
                For the security of your child, you must create an account to
                place an order.
              </p>
              <p>
                Already have an account?{' '}
                <StyledLink href="/account-lookup">Log in</StyledLink>.
              </p>
              <form onSubmit={this.handleOnSubmit} className="signup-form">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    aria-describedby="emailHelp"
                    onChange={this.handleOnChange}
                    placeholder="name@example.com"
                  />
                </div>
                {this.renderEmailErrors()}
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    className="form-control"
                    onChange={this.handleOnChange}
                    name="password"
                    value={password}
                    placeholder="password"
                    type={showPassword ? 'text' : 'password'}
                  />
                </div>
                <div className="form-group">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value=""
                      id="showPassword"
                      onClick={this.handleShowPasswordClick}
                    />
                    show password
                  </label>
                </div>
                {this.renderPasswordErrors()}
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return { company: state.company };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupParentStep1 }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewParentSignupFormStep1);
