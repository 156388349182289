import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import UIContext from '../../../contexts/UIContext';
import BackLink from '../shared/BackLink';
import CategoryHeader from './CategoryHeader';
import CategoryProduct from './CategoryProduct';
import cartUtils from '../../../utils/cart';
import { doesCategoryContainsMetallicProducts } from '../../../utils/misc';
import { isParentProduct } from '../../../utils/misc';
import { isMultiConfigProduct } from '../../../utils/misc';
import formatUSD from '../../../utils/formatUSD';
import { getProductByLocation } from '../helpers/store';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50%; // 1:2 Aspect Ratio

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
  }
`;

const ContentList = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const Category = (props) => {
  const { match, history } = props;
  const categoryId = match.params.id;

  // TODO: v3 - remove when setup & testing complete
  const uiSettings = useContext(UIContext);
  const { useTestingCategoryPaths } = uiSettings;
  const [category, setCategory] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [isNthFree, setIsNthFree] = useState(false);
  const [
    categoryContainsMetallicProducts,
    setCategoryContainsMetallicProducts,
  ] = useState(false);
  const [metallicProductsFilterActive, setMetallicProductsFilterActive] =
    useState(false);

  const availablePoses = useSelector(
    (state) => state.additionalData.availablePoses,
  );
  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const shootProducts = useSelector((state) => state.shoot.shootProducts);
  const defaultPaperType = useSelector(
    (state) => state.shoot?.shoot?.pricing_model?.default_paper_type,
  );
  const orders = useSelector((state) => state.v3Order.orders);

  useEffect(() => {
    let categoryToUpdate;

    const firstCategoryProduct = category?.products?.[0];

    // console.log('firstCategoryProduct', firstCategoryProduct);
    // console.log('isParentProduct', isParentProduct(firstCategoryProduct));
    // console.log(
    //   'isMultiConfigProduct',
    //   isMultiConfigProduct(firstCategoryProduct),
    // );

    if (
      isParentProduct(firstCategoryProduct) &&
      !isMultiConfigProduct(firstCategoryProduct)
    ) {
      setRedirectUrl(`/v3/products/${productId}/product/0#slots`);
      return;
    }

    if (
      isParentProduct(firstCategoryProduct) &&
      isMultiConfigProduct(firstCategoryProduct)
    ) {
      setRedirectUrl(`/v3/products-regular/${productId}`);
      return;
    }

    // If categoryId is 'nth-free', find the nth-free category
    if (categoryId === 'nth-free') {
      categoryToUpdate = shootProducts.find(
        (cat) => cat.category_type === 'nth_free',
      );
      setIsNthFree(true);
    } else {
      // Find the category that matches the id in the url
      categoryToUpdate = shootProducts.find(
        (cat) => cat.id.toString() === categoryId,
      );
    }

    if (categoryToUpdate?.products?.[0]?.id) {
      setProductId(categoryToUpdate.products[0].id);
    }

    if (doesCategoryContainsMetallicProducts(categoryToUpdate?.products)) {
      setCategoryContainsMetallicProducts(true);

      if (defaultPaperType === 'metallic') {
        setMetallicProductsFilterActive(true);
      }
    }

    setCategory(categoryToUpdate);

    if (categoryId === 'nth-free') {
      Swal.fire(
        'Congratulations, you qualify for a free item!',
        'Please configure your free item',
      );
    }

    setIsLoading(false);
  }, [props.hasPoses, productId, categoryId]);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  // TODO: v3 - add a proper loading spinner here
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // get array of unlocked groups for lockable products
  const cartItems = orders[selectedStudent?.id]?.cartItems || [];
  const unlockedGroups = cartUtils.unlockedGroups(cartItems);
  const highestPriceNthFreeQualifier =
    cartUtils.highestPriceNthFreeQualifier(cartItems);

  let childCategories = [];

  // If the current category has child categories, find them
  if (
    category &&
    category.child_category_ids &&
    category.child_category_ids.length > 0
  ) {
    childCategories = shootProducts.filter((potentialChildCategory) =>
      category.child_category_ids.includes(potentialChildCategory.id),
    );
  }

  const categoryProducts = category?.products
    .filter((product) => {
      // Apply the nth_free_value filter only if isNthFree is true
      if (isNthFree && product.nth_free_value > highestPriceNthFreeQualifier) {
        return false;
      }

      // Filter child_products from displaying within the category itself
      // (needed for multi_config products)
      if (product.product_type === 'child_product') {
        return false;
      }

      // If the metallic product filter is active, only include products that are metallic
      if (metallicProductsFilterActive) {
        return product.is_metallic === true;
      }
      // Otherwise, exclude products that are metallic
      return product.is_metallic !== true;
    })
    .map((product) => (
      <CategoryProduct
        key={product.id}
        useTestingCategoryPaths={useTestingCategoryPaths} // TODO: v3 - remove when setup & testing complete
        product={product}
        unlockedGroups={unlockedGroups}
        isNthFree={isNthFree}
      />
    ));

  const childCategoryProductsDisplay = childCategories.map((category) => {
    let className =
      'bnl-product-group bnl-product-group--alternate col-xs-12 col-md-6 col-lg-6 cursor-pointer';

    // TODO: address 'alternating tiles' styling of the sub-categories here (if desired)
    const product = category?.products?.[0];
    return (
      <li key={category.id} className={className}>
        <Link to={`/v3/categories/${category.id}`} className="categoryLink" />
        <h3 className="bnl-product-group__header">{category.name}</h3>
        <div className="bnl-product-group__subheader">{category.subheader}</div>
        <div className="bnl-product-group__image-container">
          <ImageContainer>
            <img
              className="bnl-product-group__image--single"
              src={category.image_url}
            />
          </ImageContainer>
        </div>
        <p className="bnl-product-regular__desc">{product?.description}</p>
        <ContentList>
          {product?.contents.map((content) => {
            return <p>{content}</p>;
          })}
        </ContentList>
      </li>
    );
  });

  // If the current category has child categories, display them
  if (childCategories.length > 0) {
    return (
      <div className="bnl-product-groups-container">
        <BackLink />
        <div className="clearfix">
          <h2 className="bnl-product-groups__header">{category.name}</h2>
        </div>
        <ul className="bnl-product-groups row">
          {childCategoryProductsDisplay}
        </ul>
      </div>
    );
  }

  // Render the products for the current category
  return (
    <div className="bnl-product-groups-container">
      {!isNthFree && <BackLink />}
      <CategoryHeader
        key={category?.id}
        isNthFree={isNthFree}
        categoryName={category?.name}
        categoryContainsMetallicProducts={categoryContainsMetallicProducts}
        highestPriceNthFreeQualifier={highestPriceNthFreeQualifier}
        metallicProductsFilterActive={metallicProductsFilterActive}
        setMetallicProductsFilterActive={setMetallicProductsFilterActive}
      />
      <ul className="bnl-product-groups row">{categoryProducts}</ul>
    </div>
  );
};

export default withRouter(Category);
