import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  clearCart,
  resetSubmittingOrder,
  storeSuccessAndBuildOrder,
} from '../../../actions/v3Order';
import OverlayLoader from '../utils/OverlayLoader';

const SuccessParagraph = styled.p`
  font-size: 16px;
  margin-top: 16px;
  font-weight: 600;
`;

const Checkout = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const query = new URLSearchParams(props.location.search);
  const sessionId = query.get('session_id');
  const uuid = query.get('cart_uuid');

  useEffect(() => {
    // clear the cart immediately
    dispatch(clearCart());

    // reset the order-submitting state
    dispatch(resetSubmittingOrder());

    const fetchOrder = async () => {
      const result = await dispatch(storeSuccessAndBuildOrder(sessionId, uuid));

      console.log('result', result);
      // setOrderId(result.orderId);
      setIsLoading(false);
    };

    fetchOrder();
  }, [sessionId, storeSuccessAndBuildOrder]);

  return (
    <>
      {isLoading ? (
        <>
          <OverlayLoader />
          <div className="text-center">
            <h1>Submitting...</h1>
            <SuccessParagraph>Your order is submitting.</SuccessParagraph>
            <SuccessParagraph>
              Please do not close or refresh this page.
            </SuccessParagraph>
          </div>
        </>
      ) : (
        <div className="text-center">
          <h1>Success!</h1>
          <SuccessParagraph>Your order has been submitted.</SuccessParagraph>
          <SuccessParagraph>
            You will receive an email confirmation soon with your order details.
            You can also view your order on the 'My Orders' page.
          </SuccessParagraph>
          <p style={{ marginTop: '16px' }}>
            <Link
              to="/dashboard"
              className="btn btn-primary"
              style={{
                marginTop: '8px',
                backgroundColor: '#479de6',
                border: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Back to Home
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default Checkout;
