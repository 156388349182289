/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ConfigScreen from './configScreen';
import { useSelector } from 'react-redux';
import { getTotalPages, addToConfig } from './utils';
import formatUSD from '../../../utils/formatUSD';
import { useCurrentOrderSinglePersonalizationValue } from '../../../hooks/useCurrentOrderSinglePersonalizationValue';

const Input = styled.input`
  outline: none;
  padding: 15px 20px;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  :focus {
    border: 2px solid #479de3;
  }
`;

const Header = styled.h3`
  font-size: 24px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 16px !important;
  padding: 0;
`;

const ActionButton = styled.button`
  border: none;
  align-self: stretch;
  background: #479DE3;
  height: auto;
  padding: 10px;
  font-size 14px;
  color: white;
  border-radius: 3px;
  font-weight: 800;
  margin-top: 16px;
  margin-right: 10px;

  :disabled {
    background: gray;
    pointer-events: none;
    cursor: initial;
    opacity: 0.3;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const Disclaimer = styled.div`
  margin-top: 15px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
`;

export const Personalization = ({ history }) => {
  const dispatch = useDispatch();
  const currentIndex = history.location.pathname.split('/').pop();
  const { v3Order, additionalData, shoot } = useSelector((s) => s);
  const personalizationProduct = useSelector(
    (state) => state.shoot.personalizationProduct,
  );
  const currentOrderSinglePersonalizationValue =
    useCurrentOrderSinglePersonalizationValue();
  const postpayRequiresSameValuePersonalization = useSelector(
    (state) => state.shoot.postpayRequiresSameValuePersonalization,
  );

  const product = v3Order.productConfig
    ? v3Order.productConfig[currentIndex]?.product
    : null;
  const personalization_required =
    product?.personalization_status === 'personalization_required';

  const {
    isPrepay,
    prepayRequiresSameValuePersonalization,
    shoot: innerShoot,
  } = shoot;

  const personalizationSetToSameValue =
    (isPrepay && prepayRequiresSameValuePersonalization) ||
    (!isPrepay && postpayRequiresSameValuePersonalization);

  const [initiallyEmpty, setInitiallyEmpty] = useState(false);

  useEffect(() => {
    setInitiallyEmpty(!value);
  }, []);

  const [value, setValue] = useState(() => {
    // If personalization set to same value
    if (personalizationSetToSameValue) {
      const previousPersonalization = Object.values(v3Order.productConfig).find(
        (config) =>
          config?.personalization !== undefined &&
          config?.personalization !== null &&
          config?.personalization !== '',
      )?.personalization;

      // If a valid personalization is found in previous productConfig, use it
      if (previousPersonalization) {
        return previousPersonalization;
      }

      // If there's a valid currentOrderSinglePersonalizationValue, use it
      if (
        currentOrderSinglePersonalizationValue !== undefined &&
        currentOrderSinglePersonalizationValue !== null &&
        currentOrderSinglePersonalizationValue !== ''
      ) {
        return currentOrderSinglePersonalizationValue;
      }
    }

    // Otherwise, default to empty string
    return '';
  });

  const submit = (personalization) => {
    const pages = getTotalPages(shoot, additionalData?.availablePoses?.length);
    addToConfig(
      dispatch,
      history,
      v3Order.productConfig,
      pages,
      personalization,
      shoot,
    );
  };

  return (
    <ConfigScreen instruction="Add a personalization for this product.">
      <Wrapper>
        {!initiallyEmpty && (
          <>
            <Header>Your Personalization Value</Header>
            <p>
              Your product will be personalized with the following value. To
              update, simply enter a new value (limit 20 characters).
            </p>
          </>
        )}
        {initiallyEmpty &&
          personalizationProduct &&
          personalizationProduct?.price > 0 && (
            <>
              <h4>Set Your Personalization Value</h4>
              <p>
                Personalize your product by entering your student's first name
                using upper and lower case letters (limit 20 characters).
              </p>
              <p>For example: Jamie</p>
              <p>
                Please do NOT use all caps or add year (year will be added
                automatically).
              </p>
              <p>
                Personalization will be applied to all products in your
                student's order that allow for personalization.
              </p>
              <p>Cost: {formatUSD(personalizationProduct.price)}</p>
              <p>To skip, simply leave the personalization field blank.</p>
            </>
          )}
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Personalization name"
        />
        <Row>
          {/* <div style={{ flex: 1 }} /> */}
          <ActionButton onClick={() => submit(value)}>
            {initiallyEmpty ? 'Add Personalization' : 'Confirm Personalization'}
          </ActionButton>
          {!personalization_required && initiallyEmpty && (
            <ActionButton
              style={{ background: 'gray' }}
              onClick={() => submit(null)}
            >
              Skip Personalization
            </ActionButton>
          )}
        </Row>
        {personalizationSetToSameValue && (
          <Disclaimer>
            Note: for products that are personalized, the same personalization
            value will be applied to each product.
          </Disclaimer>
        )}
      </Wrapper>
    </ConfigScreen>
  );
};

export default withRouter(Personalization);
